import React, { useEffect, useState } from "react";
import "./media.css";

const textInit = [
  {
    title: "platform",
    column: "Platform Name",
    value: "-",
  },
  {
    title: "spend",
    column: "Spend",
    value: "-",
  },
  {
    title: "revenue",
    column: "Revenue",
    value: "-",
  },
  {
    title: "roas",
    column: "ROAS",
    value: "-",
  },
  {
    title: "vtr",
    column: "VTR",
    value: "-",
  },
  {
    title: "cpv",
    column: "CPV",
    value: "-",
  },
  {
    title: "cpc",
    column: "CPC",
    value: "-",
  },
  {
    title: "impressions",
    column: "Impressions",
    value: "-",
  },
  {
    title: "engagements",
    column: "Engagements",
    value: "-",
  },
  {
    title: "clicks",
    column: "Clicks",
    value: "-",
  },
  {
    title: "reach",
    column: "Reach",
    value: "-",
  },
  {
    title: "views",
    column: "Views",
    value: "-",
  },
  {
    title: "cpm",
    column: "CPM",
    value: "-",
  },
  {
    title: "duration",
    column: "Video Length",
    value: "-",
  },
  {
    title: "thumbnail",
    column: "Thumbnail",
    value: "thumbnail"
  }
];

const MediaListItem = ({
  data,
  deleteCreative = () => {},
  columns,
  selectedColumns,
}) => {
  const [text, setText] = useState(
    selectedColumns.map((column) => { return textInit.find((t) => t.column === column) })
  );

  useEffect(() => {
    setText(
      selectedColumns.map((column) => {
        let text = textInit.find((t) => t.column === column);
        let temp = { ...text };
        if (data[text.title]) {
          temp.value = data[text.title];
          switch (text.title) {
            case "platform":
              temp.value = temp.value[0].toUpperCase() + temp.value.slice(1);
              break;
            case "revenue":
            case "spend":
              temp.value =
                "$" +
                parseInt(temp.value)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              break;
            case "cpv":
            case "cpm":
            case "cpc":
              temp.value =
                "$" +
                parseFloat(temp.value)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              break;
            case "vtr":
              temp.value =
                parseFloat(temp.value)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
              break;
            case "roas":
              temp.value =
                parseFloat(temp.value)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              break;
            case "impressions":
            case "engagements":
            case "clicks":
            case "reach":
            case "views":
              temp.value = temp.value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              break;
          }
        }
        return temp;
      })
    );
  }, [data, selectedColumns]);

  const getYtThumb = (url) => {
    let regex =
      /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    return "https://i.ytimg.com/vi/" + regex.exec(url)[3] + "/mqdefault.jpg";
  };

  return (
    <tr>
      {text.map((item, index) => (
        item.value === 'thumbnail' ? (
          data.creative_type === "image" ? (
            <td key={index} style={{ maxWidth: '185px' }} className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-6`}>
              <img
                src={data.creative_url}
                className="w-full h-full shadow-lg object-cover transition-all duration-500"
              />
            </td>
          ) : data.creative_type === "video" &&
            data.creative_url &&
            data.creative_url.includes("youtube") ? (
            <td key={index} style={{ maxWidth: '185px' }} className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-6`}>
              <div className="w-full h-full transition-all duration-500">
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allow="fullscreen;"
                  allowFullScreen
                  src={data.creative_url}
                ></iframe>
              </div>
            </td>
          ) : (
            <td key={index} style={{ maxWidth: '185px' }} className={`whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-white sm:pl-6`}>
              <video
                width="100%"
                height="100%"
                controls
                className="video transition-all duration-500"
                src={data.creative_url}
              ></video>
            </td>
          )
        ) : (
          <td className={`whitespace-nowrap px-3 py-4 text-sm text-white`} key={index}>
            {item.value}
          </td>
        )
      ))}

      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <a onClick={() => deleteCreative(data)}>
          <i className="fas fa-trash text-white"></i>
        </a>
      </td>
    </tr>
  );
};

export default MediaListItem;
