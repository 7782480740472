import React, { useEffect, useState } from "react";
import Filter from "./filter";
import Media from "./media";
import MediaListItem from "./media_list_item";
import DataFilter from "./dataFilter";
import "../tailwind.scss";
import * as Axios from "./axios";
import Tour from "reactour";
import moment from "moment";
import ColumnSelector from "./ColumnSelector";

const columns = [
  "Thumbnail",
  "Platform Name",
  "Spend",
  "Revenue",
  "ROAS",
  "VTR",
  "CPV",
  "CPC",
  "Impressions",
  "Engagements",
  "Clicks",
  "Reach",
  "Views",
  "CPM",
  "Video Length",
];

const optInit = [
  {
    title: "Platform",
    value: "",
    values: ["All", "Facebook", "Instagram", "Messenger", "YouTube"],
    disabled: false,
  },
  {
    title: "Age",
    value: "",
    values: [
      "All",
      "18 to 24",
      "25 to 34",
      "35 to 44",
      "45 to 54",
      "55 to 64",
      "65+",
    ],
    disabled: false,
  },
  {
    title: "Gender",
    value: "",
    values: ["All", "Male", "Female", "Unknown"],
    disabled: false,
  },
  {
    title: "Media Type",
    value: "",
    values: ["All", "Text", "Image", "Video", "Share"],
    disabled: false,
  },
  {
    title: "Sort by",
    value: "",
    values: ["Spend", "ROAS", "VTR"],
    // values: ["Spend", "Revenue", "ROAS", "VTR", "CPV/CPC"],
    disabled: false,
    opt: null,
  },
];

const CreativeStats = (props) => {
  const { accountId, addData } = props;
  const [options, setOptions] = useState(optInit);
  const [data, setData] = useState([]);
  const [dates, setDates] = useState({
    startDate: moment().subtract(14, "days"),
    endDate: moment().subtract(1, "days"),
  });
  const [minSpend, setMinSpend] = useState('');
  const [maxSpend, setMaxSpend] = useState('');
  const [view, setView] = useState("grid");
  const [loadingData, setloadingData] = useState(false);
  const [thumbCols, setthumbCols] = useState(4);
  const [showStats, setShowStats] = useState(true);
  const [tourOpen, setTourOpen] = useState(
    localStorage.getItem("tour_2_shown") !== "true"
  );
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [sortColumns, setSortColumns] = useState(columns);

  const steps = [
    {
      selector: "#tour-filter-1",
      content:
        "You can filter creative by selecting an option from one of the drop down menus.",
    },
    {
      selector: "#tour-filter-2",
      content:
        "You can also select a date range by selecting the desired timeframe and clicking apply.",
    },
  ];

  useEffect(() => {
    const storedColumns = JSON.parse(localStorage.getItem("selectedColumns"));
    const storedSortColumns = JSON.parse(localStorage.getItem("sortColumns"));
    if (storedColumns) {
      setSelectedColumns(storedColumns);
    }
    if (storedSortColumns) {
      setSortColumns(storedSortColumns);
    }
  }, []);

  const closeTour = () => {
    localStorage.setItem("tour_2_shown", true);
    setTourOpen(false);
  };

  const onSelect = (title, value) => {
    if (title === "Platform") {
      setOptions((options) => {
        if (value === "YouTube") {
          return options.map((option) => {
            if (option.title === "Platform") {
              option.value = value;
            } else if (option.title === "Gender") {
              option.disabled = true;
              option.value = "All";
            } else if (option.title === "Media Type") {
              option.disabled = true;
              option.value = "Video";
            } else if (option.title === "Age") {
              option.disabled = true;
              option.value = "All";
            }
            return option;
          });
        } else {
          return options.map((option) => {
            if (option.title === "Platform") {
              option.value = value;
            } else if (option.title === "Gender") {
              option.disabled = false;
            } else if (option.title === "Media Type") {
              option.disabled = false;
            } else if (option.title === "Age") {
              option.disabled = false;
            }
            return option;
          });
        }
      });
    } else {
      setOptions((options) => {
        let pos = options.findIndex((option) => option.title === title);
        if (title !== "Sort by") {
          return [
            ...options.slice(0, pos),
            {
              ...options[pos],
              value: value,
            },
            ...options.slice(pos + 1),
          ];
        } else {
          return [
            ...options.slice(0, pos),
            {
              ...options[pos],
              value: value,
              opt: !options[pos].opt,
            },
            ...options.slice(pos + 1),
          ];
        }
      });
    }
  };

  const deleteCreative = (obj) => {
    if (
      window.confirm(
        "Are you sure you want to delete all data for this creative?"
      )
    ) {
      Axios.destroy(`/creative_stats/id`, {
        account_id: accountId,
        creative_url: obj.creative_url,
      });

      setData((data) =>
        data.filter((creative) => creative.creative_url !== obj.creative_url)
      );
    }
  };

  const onSliderChange = (event) => {
    setthumbCols(event.target.value);
  };

  useEffect(async () => {
    setloadingData(true);

    let params = {};
    options.map((option) => {
      let value = option.value;
      let age_min, age_max;
      if (value) {
        switch (option.title) {
          case "Platform":
            params["platform"] = value.toLowerCase();
            break;
          case "Age":
            if (value === "All") {
              age_min = 0;
              age_max = 0;
            } else {
              value = value.split(" to ");
              age_min = parseInt(value[0]);
              age_max = parseInt(value[1]) || 0;
            }
            params["age_min"] = age_min;
            params["age_max"] = age_max;
            break;
          case "Gender":
            params["gender"] = value.toLowerCase();
            break;
          case "Media Type":
            params["creative_type"] = value.toLowerCase();
            break;
          case "Sort by":
            params["order_by"] =
              // (option.opt ? "" : "-") +
              "-" + value.toLowerCase().replace(/cpv\/cpc/g, "cpv");
            break;
          default:
            break;
        }
      }
    });

    if (dates.startDate) {
      params["start_date"] = dates.startDate.format().slice(0, 10);
    }
    if (dates.endDate) {
      params["end_date"] = dates.endDate.format().slice(0, 10);
    }
    if (minSpend) {
      params["min_spend"] = minSpend;
    }
    if (maxSpend) {
      params["max_spend"] = maxSpend;
    }

    params["account"] = accountId;

    const res = await Axios.get(`/creative_stats.json`, params);
    console.log(res);
    setData(res);

    setloadingData(false);
  }, [options, dates, minSpend, maxSpend]);

  const setColumns = (columns) => {
    setSelectedColumns(columns);
  };

  return (
    <div className="m-6 sm:m-12 flex flex-col">
      <div className="flex flex-row justify-between items-baseline">
        {addData && (
          <div>
            <a href="/creative_stats/import" className="btn btn-primary mb-2">
              Import Data
            </a>
          </div>
        )}
        <div className="flex flex-row items-baseline space-x-4">
          <div className="items-baseline space-x-4">
            <label className="text-white">Items per row [{thumbCols}]</label>
            <input
              type='range'
              onChange={onSliderChange}
              min={1}
              max={12}
              step={1}
              value={thumbCols}
            ></input>
          </div>

          <div className="items-baseline space-x-4">
            <label className="text-white">
              Show Stats
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-white ml-2"
                checked={showStats}
                onChange={() => setShowStats(!showStats)}
              />
            </label>
          </div>

          {view == "grid" && (
            <a
              className="text-white set-links-display mr-2"
              onClick={() => setView("list")}
              href="#"
            >
              <i className="fas fa-list fa-fw"></i>
              <span>Show as List</span>
            </a>
          )}
          {view == "list" && (
            <>
              <a
                className="text-white set-links-display mr-2"
                onClick={() => setView("grid")}
                href="#"
              >
                <i className="fas fa-th fa-fw"></i>
                <span>Show as Grid</span>
              </a>
              <div className="flex flex-row items-center space-x-1">
                <i className="fas fa-filter fa-fw text-white"></i>
                <span className="text-white">
                  <ColumnSelector columns={columns} setColumns={setColumns} />
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col mt-4">
        <div className="flex flex-col lg:flex-row justify-between items-center w-100">
          <p className="text-3xl text-white font-extrabold">
            Creatives Performance
          </p>
          <Tour
            steps={steps}
            showNumber={false}
            showCloseButton={false}
            showNavigationNumber={false}
            disableInteraction={true}
            isOpen={tourOpen}
            onRequestClose={closeTour}
            lastStepNextButton={<button>Close</button>}
          />
        </div>
        <div className="flex flex-row justify-between items-baseline">
          <div
            id="tour-filter-1"
            className="mt-4 grid grid-flow-row grid-cols-1 sm:grid-cols-2 md:grid-cols-5"
          >
            {options.slice(0, 4).map((option, index) => (
              <Filter
                options={option}
                key={index}
                onSelect={onSelect}
                className="flex-1"
              />
            ))}
            <div className="lg:flex-1 ml-0 lg:ml-12 grid grid-flow-row grid-cols-1">
              {options.slice(4).map((option, index) => (
                <Filter
                  options={option}
                  onSelect={onSelect}
                  key={index}
                  className="flex-1"
                />
              ))}
            </div>
          </div>

          <div
            id="tour-filter-2"
            className="flex flex-col sm:flex-row sm:items-center lg:justify-end"
          >
            <DataFilter setDates={setDates} loadingData={loadingData} setMinSpend={setMinSpend} setMaxSpend={setMaxSpend} />
          </div>
        </div>
      </div>

      {view == "list" && !loadingData && data && data.length == 0 ? (
        <h4 className="mt-4 text-lg text-gray-100">
          No data is available for selected filters.
        </h4>
      ) : (
        view == "list" &&
        !loadingData && (
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        {selectedColumns.map((column) => (
                          <th
                            scope="col"
                            className={`px-3 py-3.5 text-left text-sm font-semibold text-white`}
                          >
                            {column}
                          </th>
                        ))}
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {data.map((datum, index) => (
                        <MediaListItem
                          key={index}
                          data={datum}
                          deleteCreative={deleteCreative}
                          columns={sortColumns}
                          selectedColumns={selectedColumns}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      {view == "grid" && !loadingData && data && data.length == 0 ? (
        <h4 className="mt-4 text-lg text-gray-100">
          No data is available for selected filters.
        </h4>
      ) : (
        view == "grid" &&
        !loadingData && (
          <div className={"grid grid-flow-row mt-4 sm:mt-12 gap-4 sm:gap-4 lg:gap-8 " + 'grid-cols-' + thumbCols}>
            {data.map((datum, index) => (
              <Media key={index} data={datum} showStats={showStats} cols={thumbCols} />
            ))}
          </div>
        )
      )}

      {loadingData && (
        <h4 className="mt-4 text-lg text-gray-100">
          <div className="loader">Loading...</div>
        </h4>
      )}
    </div>
  );
};

export default CreativeStats;
