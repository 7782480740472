import React, { useState, useEffect } from "react";
import Modal from "react-modal";

const ColumnSelector = ({ columns, setColumns }) => {
  const [selectedColumns, setSelectedColumns] = useState(columns);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortColumns, setSortColumns] = useState(columns);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#ffffff",
      width: "50%",
      borderRadius: "0.5rem",
    },
  };

  useEffect(() => {
    const storedColumns = JSON.parse(localStorage.getItem("selectedColumns"));
    const storedSortColumns = JSON.parse(localStorage.getItem("sortColumns"));
    if (storedColumns) {
      setSelectedColumns(storedColumns);
    }
    setSortColumns(storedSortColumns && storedSortColumns.length > 0 ? storedSortColumns : columns);
  }, []);

  useEffect(() => {
    localStorage.setItem('sortColumns', JSON.stringify(sortColumns));
  }, [sortColumns]);

  useEffect(() => {
    localStorage.setItem('selectedColumns', JSON.stringify(selectedColumns));
  }, [selectedColumns]);

  const handleCheckboxChange = (column) => {
    setSelectedColumns((prevSelected) => {
      if (prevSelected.includes(column)) {
        return sortColumns.filter((item) => selectedColumns.includes(item) && item !== column);
      } else {
        return sortColumns.filter((item) => selectedColumns.includes(item) || item === column);
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleApply = () => {
    localStorage.setItem("selectedColumns", JSON.stringify(selectedColumns));
    setColumns(selectedColumns);
    setIsModalOpen(false);
  };

  const handleReset = () => {
    localStorage.setItem("selectedColumns", JSON.stringify(columns));
    setColumns(columns);
    setSelectedColumns(columns);
    localStorage.setItem("sortColumns", JSON.stringify(columns));
    setSortColumns(columns);
    setIsModalOpen(false);
  }

  const handleDragStart = (event, id) => {
    event.dataTransfer.setData('id', id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, element) => {
    event.preventDefault();
    const draggedId = event.dataTransfer.getData('id');
    const droppedIndex = element.getAttribute('data-idx');
    const draggedItem = sortColumns.find((item) => item === draggedId);
    const remainingItems = sortColumns.filter((item) => item !== draggedId);
    const updatedItems = [
      ...remainingItems.slice(0, droppedIndex),
      draggedItem,
      ...remainingItems.slice(droppedIndex),
    ];
    setSortColumns(updatedItems);
    setSelectedColumns(updatedItems.filter((item) => selectedColumns.includes(item)));
  };

  return (
    <div>
      <button onClick={() => setIsModalOpen(true)}>Select Columns</button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCancel}
        ariaHideApp={false}
        style={customStyles}
      >
        <div className="flex flex-row justify-between items-center pb-2 border-b-2 mb-4">
          <h2 className="text-xl font-bold">Select Columns</h2>
          <button onClick={handleCancel}>
            <i className="text-black text-xl fa fa-times"></i>
          </button>
        </div>

        <ul onDragOver={handleDragOver} onDrop={(event) => handleDrop(event, event.target)}>
          {sortColumns.map((column, index) => (
            <li key={column} data-idx={index} draggable onDragStart={(event) => handleDragStart(event, column)}>
              <i className="fas fa-bars" style={{ cursor: 'grab' }}></i>&nbsp;&nbsp;
              <label>
                <input
                  type="checkbox"
                  checked={selectedColumns.includes(column)}
                  onChange={() => handleCheckboxChange(column)}
                />
                <span className="pl-2" style={{ cursor: 'grab' }} data-idx={index}>{column}</span>
              </label>
            </li>
          ))}
        </ul>
        <hr className="my-2" />
        <button
          className="p-2 rounded-md bg-blue-200 text-blue-800 hover:bg-blue-300 font-semibold border border-blue-400"
          onClick={handleApply}
        >
          Apply Columns
        </button>
        &nbsp;&nbsp;
        <button
          className="p-2 rounded-md bg-gray-200 text-blue-800 hover:bg-blue-300 font-semibold border border-blue-400"
          onClick={handleReset}
        >
          Reset to Default
        </button>
      </Modal>
    </div>
  );
};

export default ColumnSelector;
