import axios from "axios";

const instance = axios.create({
  baseURL: "/api/",
});

instance.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response);
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const get = (url, params) => {
  if (params && Object.keys(params).length !== 0) {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    url = url + "?" + query;
  }
  console.log(url);

  return instance.get(url);
};

export const destroy = (url, params) => {
  if (params && Object.keys(params).length !== 0) {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    url = url + "?" + query;
  }
  console.log(url);

  return instance.delete(url);
};

export default instance;
