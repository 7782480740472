/*
File generated by js-routes 1.4.14
Based on Rails 6.1.3.1 routes of Slingshot::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accept_user_invitation => /users/invitation/accept(.:format)
  // function(options)
  accept_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account => /accounts/:id(.:format)
  // function(id, options)
  account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_app_link => /accounts/:account_id/app_links/:id(.:format)
  // function(account_id, id, options)
  account_app_link_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"app_links",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// account_app_links => /accounts/:account_id/app_links(.:format)
  // function(account_id, options)
  account_app_links_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"app_links",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_categories => /accounts/:account_id/categories(.:format)
  // function(account_id, options)
  account_categories_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_category => /accounts/:account_id/categories/:id(.:format)
  // function(account_id, id, options)
  account_category_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// account_creatives => /accounts/:account_id/creatives(.:format)
  // function(account_id, options)
  account_creatives_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"creatives",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_document => /accounts/:account_id/documents/:id(.:format)
  // function(account_id, id, options)
  account_document_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// account_documents => /accounts/:account_id/documents(.:format)
  // function(account_id, options)
  account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_external_link => /accounts/:account_id/links/:id(.:format)
  // function(account_id, id, options)
  account_external_link_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// account_external_links => /accounts/:account_id/links(.:format)
  // function(account_id, options)
  account_external_links_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"links",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_image => /accounts/:account_id/images/:id(.:format)
  // function(account_id, id, options)
  account_image_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// account_images => /accounts/:account_id/images(.:format)
  // function(account_id, options)
  account_images_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_label => /accounts/:account_id/labels/:id(.:format)
  // function(account_id, id, options)
  account_label_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"labels",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// account_labels => /accounts/:account_id/labels(.:format)
  // function(account_id, options)
  account_labels_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"labels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// account_video => /accounts/:account_id/videos/:id(.:format)
  // function(account_id, id, options)
  account_video_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// account_videos => /accounts/:account_id/videos(.:format)
  // function(account_id, options)
  account_videos_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// accounts => /accounts(.:format)
  // function(options)
  accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// additional_account_documents => /accounts/:account_id/documents/additional(.:format)
  // function(account_id, options)
  additional_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"additional",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_company_reporting => /admin/company_reportings/:id(.:format)
  // function(id, options)
  admin_company_reporting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"company_reportings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_company_reportings => /admin/company_reportings(.:format)
  // function(options)
  admin_company_reportings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"company_reportings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// archive_account_document => /accounts/:account_id/documents/:id/archive(.:format)
  // function(account_id, id, options)
  archive_account_document_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// archived_account_documents => /accounts/:account_id/documents/archived(/:type)(.:format)
  // function(account_id, options)
  archived_account_documents_path: Utils.route([["account_id",true],["type",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"archived",false],[2,[1,[2,[7,"/",false],[3,"type",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// archived_account_external_links => /accounts/:account_id/links/archived(.:format)
  // function(account_id, options)
  archived_account_external_links_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[6,"archived",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// archived_account_images => /accounts/:account_id/images/archived(.:format)
  // function(account_id, options)
  archived_account_images_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"archived",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// archived_accounts => /accounts/archived(.:format)
  // function(options)
  archived_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"archived",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// archived_campaigns => /campaigns/archived(.:format)
  // function(options)
  archived_campaigns_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[6,"archived",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_activate_users => /admin/users/bulk_activate(.:format)
  // function(options)
  bulk_activate_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"bulk_activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_archive_account_documents => /accounts/:account_id/documents/bulk_archive(.:format)
  // function(account_id, options)
  bulk_archive_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"bulk_archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_archive_accounts => /accounts/bulk_archive(.:format)
  // function(options)
  bulk_archive_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"bulk_archive",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_deactivate_users => /admin/users/bulk_deactivate(.:format)
  // function(options)
  bulk_deactivate_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"bulk_deactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_destroy_account_documents => /accounts/:account_id/documents/bulk_destroy(.:format)
  // function(account_id, options)
  bulk_destroy_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_destroy_companies => /companies/bulk_destroy(.:format)
  // function(options)
  bulk_destroy_companies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_download_account_images => /accounts/:account_id/images/bulk_download(.:format)
  // function(account_id, options)
  bulk_download_account_images_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"bulk_download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_email_account_documents => /accounts/:account_id/documents/bulk_email(.:format)
  // function(account_id, options)
  bulk_email_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"bulk_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_email_form_account_documents => /accounts/:account_id/documents/bulk_email_form(.:format)
  // function(account_id, options)
  bulk_email_form_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"bulk_email_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_invite_users => /admin/users/bulk_invite(.:format)
  // function(options)
  bulk_invite_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"bulk_invite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_remove_account_images => /accounts/:account_id/images/bulk_remove(.:format)
  // function(account_id, options)
  bulk_remove_account_images_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"bulk_remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_remove_account_videos => /accounts/:account_id/videos/bulk_remove(.:format)
  // function(account_id, options)
  bulk_remove_account_videos_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[6,"bulk_remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_restore_account_documents => /accounts/:account_id/documents/bulk_restore(.:format)
  // function(account_id, options)
  bulk_restore_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"bulk_restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_restore_accounts => /accounts/bulk_restore(.:format)
  // function(options)
  bulk_restore_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"bulk_restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_update_account_documents => /accounts/:account_id/documents/bulk_update(.:format)
  // function(account_id, options)
  bulk_update_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"bulk_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_update_account_images => /accounts/:account_id/images/bulk_update(.:format)
  // function(account_id, options)
  bulk_update_account_images_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"bulk_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_update_account_videos => /accounts/:account_id/videos/bulk_update(.:format)
  // function(account_id, options)
  bulk_update_account_videos_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[6,"bulk_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// bulk_update_menu_items => /menu_items/bulk_update(.:format)
  // function(options)
  bulk_update_menu_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"menu_items",false],[2,[7,"/",false],[2,[6,"bulk_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_update_users => /admin/users/bulk_update(.:format)
  // function(options)
  bulk_update_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"bulk_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// campaign => /campaigns/:id(.:format)
  // function(id, options)
  campaign_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// campaigns => /campaigns(.:format)
  // function(options)
  campaigns_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// categories => /categories(.:format)
  // function(options)
  categories_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// category => /categories/:id(.:format)
  // function(id, options)
  category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// charts_account_creatives => /accounts/:account_id/creatives/charts(.:format)
  // function(account_id, options)
  charts_account_creatives_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"creatives",false],[2,[7,"/",false],[2,[6,"charts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// choose_thumb_account_video => /accounts/:account_id/videos/:id/choose_thumb(.:format)
  // function(account_id, id, options)
  choose_thumb_account_video_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"choose_thumb",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// companies => /companies(.:format)
  // function(options)
  companies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// company => /companies/:id(.:format)
  // function(id, options)
  company_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// company_user => /company/users/:id(.:format)
  // function(id, options)
  company_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// company_user_toggle_active_status => /company/users/:user_id/toggle_active_status(.:format)
  // function(user_id, options)
  company_user_toggle_active_status_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"toggle_active_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// company_users => /company/users(.:format)
  // function(options)
  company_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// creative_stat => /creative_stats/:id(.:format)
  // function(id, options)
  creative_stat_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"creative_stats",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// creative_stats => /creative_stats(.:format)
  // function(options)
  creative_stats_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"creative_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// csv_export_account_documents => /accounts/:account_id/documents/csv_export(.:format)
  // function(account_id, options)
  csv_export_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"csv_export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// doc_form_account_documents => /accounts/:account_id/documents/doc_form(.:format)
  // function(account_id, options)
  doc_form_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"doc_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// download_account_image => /accounts/:account_id/images/:id/download(.:format)
  // function(account_id, id, options)
  download_account_image_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_account => /accounts/:id/edit(.:format)
  // function(id, options)
  edit_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_account_app_link => /accounts/:account_id/app_links/:id/edit(.:format)
  // function(account_id, id, options)
  edit_account_app_link_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"app_links",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_account_category => /accounts/:account_id/categories/:id/edit(.:format)
  // function(account_id, id, options)
  edit_account_category_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_account_document => /accounts/:account_id/documents/:id/edit(.:format)
  // function(account_id, id, options)
  edit_account_document_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_account_external_link => /accounts/:account_id/links/:id/edit(.:format)
  // function(account_id, id, options)
  edit_account_external_link_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_account_image => /accounts/:account_id/images/:id/edit(.:format)
  // function(account_id, id, options)
  edit_account_image_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_account_label => /accounts/:account_id/labels/:id/edit(.:format)
  // function(account_id, id, options)
  edit_account_label_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"labels",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_account_video => /accounts/:account_id/videos/:id/edit(.:format)
  // function(account_id, id, options)
  edit_account_video_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_campaign => /campaigns/:id/edit(.:format)
  // function(id, options)
  edit_campaign_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_category => /categories/:id/edit(.:format)
  // function(id, options)
  edit_category_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_company => /companies/:id/edit(.:format)
  // function(id, options)
  edit_company_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_company_user => /company/users/:id/edit(.:format)
  // function(id, options)
  edit_company_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"company",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_creative_stat => /creative_stats/:id/edit(.:format)
  // function(id, options)
  edit_creative_stat_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"creative_stats",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_label => /labels/:id/edit(.:format)
  // function(id, options)
  edit_label_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"labels",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_menu_item => /menu_items/:id/edit(.:format)
  // function(id, options)
  edit_menu_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"menu_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_multiple_account_images => /accounts/:account_id/images/multiple(.:format)
  // function(account_id, options)
  edit_multiple_account_images_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_multiple_account_videos => /accounts/:account_id/videos/multiple(.:format)
  // function(account_id, options)
  edit_multiple_account_videos_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[6,"multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_notifications => /notifications/edit(.:format)
  // function(options)
  edit_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_tags_account_image => /accounts/:account_id/images/:id/edit_tags(.:format)
  // function(account_id, id, options)
  edit_tags_account_image_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_tags_account_video => /accounts/:account_id/videos/:id/edit_tags(.:format)
  // function(account_id, id, options)
  edit_tags_account_video_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_user => /admin/users/:id/edit(.:format)
  // function(id, options)
  edit_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// featured_account_documents => /accounts/:account_id/documents/featured(.:format)
  // function(account_id, options)
  featured_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"featured",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// help => /help(.:format)
  // function(options)
  help_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"help",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// import_creative_stats => /creative_stats/import(.:format)
  // function(options)
  import_creative_stats_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"creative_stats",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// internal_account_documents => /accounts/:account_id/documents/internal(.:format)
  // function(account_id, options)
  internal_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"internal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// label => /labels/:id(.:format)
  // function(id, options)
  label_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"labels",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// labels => /labels(.:format)
  // function(options)
  labels_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"labels",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// letter_opener_web => /letter_opener
  // function(options)
  letter_opener_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"letter_opener",false]]),
// letter_opener_web.clear_letters => /letter_opener/clear(.:format)
  // function(options)
  letter_opener_web_clear_letters_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"letter_opener",false]],[7,"/",false]],[2,[6,"clear",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// letter_opener_web.delete_letter => /letter_opener/:id(.:format)
  // function(id, options)
  letter_opener_web_delete_letter_path: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"letter_opener",false]],[7,"/",false]],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// letter_opener_web.letters => /letter_opener/
  // function(options)
  letter_opener_web_letters_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"letter_opener",false]],[7,"/",false]]),
// letter_opener_web.letter => /letter_opener/:id(/:style)(.:format)
  // function(id, options)
  letter_opener_web_letter_path: Utils.route([["id",true],["style",false],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"letter_opener",false]],[7,"/",false]],[2,[3,"id",false],[2,[1,[2,[7,"/",false],[3,"style",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// list_account_documents => /accounts/:account_id/documents/list(/:type)(.:format)
  // function(account_id, options)
  list_account_documents_path: Utils.route([["account_id",true],["type",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"list",false],[2,[1,[2,[7,"/",false],[3,"type",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// list_account_external_links => /accounts/:account_id/links/list(.:format)
  // function(account_id, options)
  list_account_external_links_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// list_account_images => /accounts/:account_id/images/list(.:format)
  // function(account_id, options)
  list_account_images_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// list_account_videos => /accounts/:account_id/videos/list(.:format)
  // function(account_id, options)
  list_account_videos_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[6,"list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// magic_link => /
  // function(options)
  magic_link_path: Utils.route([], {}, [7,"/",false]),
// magic_link.magic_links => //magic_links(.:format)
  // function(options)
  magic_link_magic_links_path: Utils.route([["format",false]], {}, [2,[2,[7,"/",false],[7,"/",false]],[2,[6,"magic_links",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// magic_link.new_magic_link => //magic_links/new(.:format)
  // function(options)
  magic_link_new_magic_link_path: Utils.route([["format",false]], {}, [2,[2,[7,"/",false],[7,"/",false]],[2,[6,"magic_links",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// magic_link.authenticate => //magic_links/authenticate(.:format)
  // function(options)
  magic_link_authenticate_path: Utils.route([["format",false]], {}, [2,[2,[7,"/",false],[7,"/",false]],[2,[6,"magic_links",false],[2,[7,"/",false],[2,[6,"authenticate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// meeting_materials_account_documents => /accounts/:account_id/documents/meeting_materials(.:format)
  // function(account_id, options)
  meeting_materials_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"meeting_materials",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// menu_item => /menu_items/:id(.:format)
  // function(id, options)
  menu_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"menu_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// menu_items => /menu_items(.:format)
  // function(options)
  menu_items_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"menu_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// modal_menu_item => /menu_items/:id/modal(.:format)
  // function(id, options)
  modal_menu_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"menu_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// model_account_documents => /accounts/:account_id/documents/model(.:format)
  // function(account_id, options)
  model_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"model",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// move_down_account_document => /accounts/:account_id/documents/:id/move_down(.:format)
  // function(account_id, id, options)
  move_down_account_document_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_down",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// move_down_account_external_link => /accounts/:account_id/links/:id/move_down(.:format)
  // function(account_id, id, options)
  move_down_account_external_link_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_down",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// move_down_menu_item => /menu_items/:id/move_down(.:format)
  // function(id, options)
  move_down_menu_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"menu_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_down",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// move_to_position_account_document => /accounts/:account_id/documents/:id/move_to_position(.:format)
  // function(account_id, id, options)
  move_to_position_account_document_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_to_position",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// move_to_position_account_external_link => /accounts/:account_id/links/:id/move_to_position(.:format)
  // function(account_id, id, options)
  move_to_position_account_external_link_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_to_position",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// move_to_position_menu_item => /menu_items/:id/move_to_position(.:format)
  // function(id, options)
  move_to_position_menu_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"menu_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_to_position",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// move_up_account_document => /accounts/:account_id/documents/:id/move_up(.:format)
  // function(account_id, id, options)
  move_up_account_document_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// move_up_account_external_link => /accounts/:account_id/links/:id/move_up(.:format)
  // function(account_id, id, options)
  move_up_account_external_link_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// move_up_menu_item => /menu_items/:id/move_up(.:format)
  // function(id, options)
  move_up_menu_item_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"menu_items",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"move_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_account => /accounts/new(.:format)
  // function(options)
  new_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_account_app_link => /accounts/:account_id/app_links/new(.:format)
  // function(account_id, options)
  new_account_app_link_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"app_links",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_account_document => /accounts/:account_id/documents/new(.:format)
  // function(account_id, options)
  new_account_document_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_account_external_link => /accounts/:account_id/links/new(.:format)
  // function(account_id, options)
  new_account_external_link_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_account_image => /accounts/:account_id/images/new(.:format)
  // function(account_id, options)
  new_account_image_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_account_video => /accounts/:account_id/videos/new(.:format)
  // function(account_id, options)
  new_account_video_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_campaign => /campaigns/new(.:format)
  // function(options)
  new_campaign_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_company => /companies/new(.:format)
  // function(options)
  new_company_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"companies",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_company_user => /company/users/new(.:format)
  // function(options)
  new_company_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"company",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_creative_stat => /creative_stats/new(.:format)
  // function(options)
  new_creative_stat_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"creative_stats",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_menu_item => /menu_items/new(.:format)
  // function(options)
  new_menu_item_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"menu_items",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_notifications => /notifications/new(.:format)
  // function(options)
  new_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user => /admin/users/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_invitation => /users/invitation/new(.:format)
  // function(options)
  new_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_mfa_session => /user_mfa_session/new(.:format)
  // function(options)
  new_user_mfa_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_mfa_session",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// notifications => /notifications(.:format)
  // function(options)
  notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// primary_account_documents => /accounts/:account_id/documents/primary(.:format)
  // function(account_id, options)
  primary_account_documents_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"primary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// remove_account_image => /accounts/:account_id/images/:id/remove(.:format)
  // function(account_id, id, options)
  remove_account_image_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// remove_account_video => /accounts/:account_id/videos/:id/remove(.:format)
  // function(account_id, id, options)
  remove_account_video_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// remove_user_invitation => /users/invitation/remove(.:format)
  // function(options)
  remove_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reset_session => /reset_session(.:format)
  // function(options)
  reset_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reset_session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// restore_account => /accounts/:id/restore(.:format)
  // function(id, options)
  restore_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// restore_account_document => /accounts/:account_id/documents/:id/restore(.:format)
  // function(account_id, id, options)
  restore_account_document_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// restore_account_external_link => /accounts/:account_id/links/:id/restore(.:format)
  // function(account_id, id, options)
  restore_account_external_link_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"links",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// restore_account_image => /accounts/:account_id/images/:id/restore(.:format)
  // function(account_id, id, options)
  restore_account_image_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// restore_campaign => /campaigns/:id/restore(.:format)
  // function(id, options)
  restore_campaign_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"restore",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// session_reset => /session/reset(.:format)
  // function(options)
  session_reset_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"session",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// set_featured_account_document => /accounts/:account_id/documents/:id/set_featured(.:format)
  // function(account_id, id, options)
  set_featured_account_document_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"set_featured",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// set_model_account_document => /accounts/:account_id/documents/:id/set_model(.:format)
  // function(account_id, id, options)
  set_model_account_document_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"set_model",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// show_primary_account_document => /accounts/:account_id/documents/:id/show_primary(.:format)
  // function(account_id, id, options)
  show_primary_account_document_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_primary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// show_primary_modal_account_document => /accounts/:account_id/documents/:id/show_primary_modal(.:format)
  // function(account_id, id, options)
  show_primary_modal_account_document_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"show_primary_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// slack_ack => /slack_ack(.:format)
  // function(options)
  slack_ack_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"slack_ack",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// solutions => /solutions(.:format)
  // function(options)
  solutions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"solutions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// tnc => /tnc(.:format)
  // function(options)
  tnc_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tnc",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// unread_notifications => /notifications/unread(.:format)
  // function(options)
  unread_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[6,"unread",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_multiple_account_images => /accounts/:account_id/images/multiple(.:format)
  // function(account_id, options)
  update_multiple_account_images_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_multiple_account_videos => /accounts/:account_id/videos/multiple(.:format)
  // function(account_id, options)
  update_multiple_account_videos_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[6,"multiple",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_tags_account_image => /accounts/:account_id/images/:id/update_tags(.:format)
  // function(account_id, id, options)
  update_tags_account_image_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_tags_account_video => /accounts/:account_id/videos/:id/update_tags(.:format)
  // function(account_id, id, options)
  update_tags_account_video_path: Utils.route([["account_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_title_account_creatives => /accounts/:account_id/creatives/update_title(.:format)
  // function(account_id, options)
  update_title_account_creatives_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"creatives",false],[2,[7,"/",false],[2,[6,"update_title",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// upload_file_account_images => /accounts/:account_id/images/upload-file(.:format)
  // function(account_id, options)
  upload_file_account_images_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"images",false],[2,[7,"/",false],[2,[6,"upload-file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// upload_file_account_videos => /accounts/:account_id/videos/upload-file(.:format)
  // function(account_id, options)
  upload_file_account_videos_path: Utils.route([["account_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"account_id",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[6,"upload-file",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user => /admin/users/:id(.:format)
  // function(id, options)
  user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_google_oauth2_omniauth_authorize => /users/auth/google_oauth2(.:format)
  // function(options)
  user_google_oauth2_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_google_oauth2_omniauth_callback => /users/auth/google_oauth2/callback(.:format)
  // function(options)
  user_google_oauth2_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_invitation => /users/invitation(.:format)
  // function(options)
  user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_manage_accounts => /admin/users/:user_id/manage_accounts(.:format)
  // function(user_id, options)
  user_manage_accounts_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"manage_accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_mfa_session => /user_mfa_session(.:format)
  // function(options)
  user_mfa_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_mfa_session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_microsoft_office365_omniauth_authorize => /users/auth/microsoft_office365(.:format)
  // function(options)
  user_microsoft_office365_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"microsoft_office365",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_microsoft_office365_omniauth_callback => /users/auth/microsoft_office365/callback(.:format)
  // function(options)
  user_microsoft_office365_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"microsoft_office365",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_toggle_active_status => /admin/users/:user_id/toggle_active_status(.:format)
  // function(user_id, options)
  user_toggle_active_status_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"toggle_active_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// users => /admin/users(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// who_has_access_account => /accounts/:id/who_has_access(.:format)
  // function(id, options)
  who_has_access_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"who_has_access",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);
