import React, { Component } from "react";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "./dateRange.css";

import { DateRangePicker } from "react-dates";

class DataFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, "year"),
      endDate: moment(),
      focusedInput: null,
      minSpend: '',
      maxSpend: ''
    };
  }

  render() {
    const handleSpendChange = (e) => {
      const target = e.target;
      const value = target.value;
      const name = target.name;

      if (name === "minSpend") {
        this.setState({ minSpend: value });
      } else if (name === "maxSpend") {
        this.setState({ maxSpend: value });
      }
    };

    return (
      <div className="cursor-default mr-1 mb-0 sm:mb-2">
        <div className="float-right">
          <DateRangePicker
            startDateId="startDate"
            endDateId="endDate"
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onDatesChange={({ startDate, endDate }) => {
              this.setState({ startDate, endDate });
            }}
            focusedInput={this.state.focusedInput}
            onFocusChange={(focusedInput) => {
              this.setState({ focusedInput });
            }}
            isOutsideRange={() => false}
          />
        </div>

        <div className="flex flex-col sm:flex-row sm:items-center lg:justify-start">
          <div className="flex flex-row items-center mr-4">
            <label className="text-white mt-1">Min Spend</label>
            <input
              type="number"
              className="form-input h-8 w-32 text-grey-100 ml-2 p-2"
              onChange={handleSpendChange}
              value={this.state.minSpend}
              name="minSpend"
              min="0"
            />
          </div>
          <div className="flex flex-row items-center mr-4">
            <label className="text-white mt-1">Max Spend</label>
            <input
              type="number"
              className="form-input h-8 w-32 text-grey-100 ml-2 p-2"
              onChange={handleSpendChange}
              value={this.state.maxSpend}
              name="maxSpend"
              min="1"
            />
          </div>
        </div>

        <button className="btn btn-primary btn-sm ml-1 mt-1 float-right"
          disabled={this.props.loadingData}
          onClick={() => {
            this.props.setDates({
              startDate: this.state.startDate,
              endDate: this.state.endDate,
            });
            this.props.setMinSpend(this.state.minSpend);
            this.props.setMaxSpend(this.state.maxSpend);
          }}
        >Apply</button>
      </div>
    );
  }
}

export default DataFilter;
