import React, { useEffect, useState } from "react";
import "./media.css";

const textInit = [
  {
    title: "spend",
    value: "-",
  },
  {
    title: "revenue",
    value: "-",
  },
  {
    title: "roas",
    value: "-",
  },
  {
    title: "vtr",
    value: "-",
  },
  {
    title: "cpv",
    value: "-",
  },
  {
    title: "cpc",
    value: "-",
  },
];

const Media = ({ data, showStats, cols }) => {
  const [text, setText] = useState(textInit);

  useEffect(() => {
    setText(
      textInit.map((text) => {
        let temp = { ...text };
        if (data[text.title]) {
          temp.value = data[text.title];
          switch (text.title) {
            case "revenue":
            case "spend":
              temp.value =
                "$" +
                parseInt(temp.value)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              break;
            case "cpv":
            case "cpm":
            case "cpc":
              temp.value =
                "$" +
                parseFloat(temp.value)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              break;
            case "vtr":
              temp.value =
                parseFloat(temp.value)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
              break;
            case "roas":
              temp.value =
                parseFloat(temp.value)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              break;
          }
        }
        return temp;
      })
    );
  }, [data]);

  return (
    <div className="card-container flex flex-col mb-12 justify-center">
      <div
        className="relative flex items-center justify-center cursor-default flex-1 flex-col card-zoom overflow-hidden"
      >
        <div className="card w-full h-36 xs:h-48 sm:h-52 lg:h-52 xl:h-60 2xl:h-72 bg-white rounded-md">
          {data.creative_type === "image" ? (
            <img
              src={data.creative_url}
              className="w-full h-full shadow-lg object-cover border border-white transition-all duration-500"
            />
          ) : data.creative_type === "video" &&
            data.creative_url && data.creative_url.includes("youtube") ? (
            <div className="w-full h-full transition-all duration-500">
              <iframe
                width="100%"
                height="100%"
                src={data.creative_url}
              ></iframe>
            </div>
          ) : (
            <video
              width="100%"
              height="100%"
              controls
              className="video transition-all duration-500"
            >
              <source src={data.creative_url} type="video/mp4" />
            </video>
          )}
        </div>
      </div>
      <div className="relative flex items-center justify-center p-1">
        <p className="uppercase tracking-wide leading-5 text-xs md:text-sm font-light text-blue-200">
          {data.platform}
        </p>
      </div>

      <div
        className={`grid-flow-row p-1 pt-4 sm:pt-8 gap-4 ${cols > 4 ? 'grid-cols-1' : 'grid-cols-3'}`}
        style={{ display: showStats ? 'grid' : 'none' }}
      >
        {text.map((item, index) => (
          <div key={index} className="flex flex-col" style={{display: cols > 4 && index > 2 ? 'none' : 'flex'}}>
            <p className="uppercase tracking-wide leading-5 text-xs md:text-sm font-light text-blue-200">
              {item.title}
            </p>
            <p className="text-base sm:text-lg text-gray-100 font-medium">
              {item.value}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Media;
